import React from "react";
import { PageLayout, Helmet, Section, InternalLink } from "@bluefin/components";
import { Header, Button } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import "../style/404.css";

export default class Page extends React.PureComponent {
  render() {
    return (
      <WebsiteLayout>
        <PageLayout hero={false} subfooter={false} className={"page-404"}>
          <Helmet>
            <meta name={"robots"} content={"noindex"} />
          </Helmet>
          <Section className={"container-404"} textAlign={"center"}>
            <Header as={"h1"} content={"404"} />
            <Header
              as={"h2"}
              content={"Oops! The page you're looking for can't be found."}
            />
            <Button
              primary={true}
              to={"/"}
              as={InternalLink}
              event={{ category: "Navigation", action: "Go To Homepage" }}
              content={"Go To Homepage"}
            />
          </Section>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}
